import { Field, Form, Formik } from "formik";
import React, { useContext, useRef } from "react";
import * as yup from "yup";
import { Container,  FormControlLabel, FormControl, useTheme, Typography, Paper } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import Divider from "@mui/material/Divider";
import DataLayerService from "utility/dataLayerService";
import Validation, { useZipCodeValidation } from "utility/validation";
import PostService from "utility/postService";
import LeafBox from "components/atoms/LeafBox";

// Import SVG Icons.
import { ReactComponent as CityWaterIcon } from "components/Forms/form-icons/city-water_15952a7bca.svg"
import { ReactComponent as WellWaterIcon } from "components/Forms/form-icons/well-water.svg";
import { ReactComponent as UnsureIcon } from "components/Forms/form-icons/unsure.svg";
import { ReactComponent as YesIcon } from "components/Forms/form-icons/yes_15968c97c1.svg";
import { ReactComponent as NoIcon } from "components/Forms/form-icons/no_15958a5221.svg";

import { ReactComponent as OwnIcon } from "components/Forms/form-icons/icons-form-LHWS-web-2022_own-off.svg";
import { ReactComponent as RentIcon } from "components/Forms/form-icons/icons-form-LHWS-web-2022_rent-off.svg";

import { FormContextLHWSMultiStepEstimate } from "./LHWSMultiStepEstimate";
import styled from "@emotion/styled";
import ZipCodeInput from "components/atoms/ZipcodeInput";
import TermAndCondition from "components/atoms/TermAndCondition";
import { PrimaryButton } from "components/atoms/LeafButtons";
import { useProduct, useComponentName, useVersion } from "hooks";
import ProgressBar from "components/atoms/ProgressBar";
import ProgressBarWithPercentage from 'components/atoms/ProgressBarWithPercentage';
import { scrollToTop } from '../../../../utility/functions';

const InitialStep = (props) => {
  const location = props.dataSet?.location ? props.dataSet.location : "multi-step-modal-form";
  const form = props.dataSet?.form ? props.dataSet.form : "modal";
  const zipSource = props.dataSet?.zipsource || "localzip";
  let zipCode = '';
  let zipcodeInputValue = '';
  let serviceAreaFlag = false;
  const product = useProduct();
  const userJourneyTracking = props.dataSet?.tracking || '';
  const componentName = props.component || 'ZipChecker';
  const formName = useComponentName(componentName);
  const subformName = useComponentName(props.modalComponent);
  let zipCodeValidationStatus = 'invalid';
  const v2 = useVersion();
  const formRef = useRef(null);

  // Zipcode Validation based on the Country Code.
  const zipCodeValidation = useZipCodeValidation();

  const { setactiveLHWSStepIndex, formData, setFormData } = useContext(FormContextLHWSMultiStepEstimate);

  const homeTypeEnable =  props.dataSet?.hometype ? props.dataSet.hometype : 'disable';

  const ValidationSchema = yup.object().shape({
    zipCode: yup
      .string()
      .matches(zipCodeValidation.validationPattern, zipCodeValidation.validationMessage)
      .required(`${zipCodeValidation.zipCodeText} is required.`)
      .test('available-zipcode', zipCodeValidation.restrictedValidationMessage, async function (zipcode) {
        if (!(formData.zipCode) && (zipcodeInputValue !== zipcode) && (zipCodeValidation.validationPattern.test(zipcode))) {
          serviceAreaFlag = await PostService.checkAvailableService(zipcode, product, zipSource);
          zipcodeInputValue = zipcode;
          zipCodeValidationStatus = (serviceAreaFlag === true) ? 'valid' : 'invalid';
          if (!serviceAreaFlag) {
            DataLayerService.formFailEvent(form, location, {'zipCode': ['OOA restriction for ' + zipcode + ' failed.']});
          }
          if (userJourneyTracking) {
            DataLayerService.zipCodeEventTracking(formName, subformName, product, zipcodeInputValue, 'completed', zipCodeValidationStatus);
          }
          // Return true if the validation passes, false if it fails
          return serviceAreaFlag;
        }
        else {
          if (formData.zipCode) {
            return true;
          }

          zipCodeValidationStatus = (serviceAreaFlag === true) ? 'valid' : 'invalid';
          if ((userJourneyTracking) && formData.zipCode !== undefined) {
            DataLayerService.zipCodeEventTracking(formName, subformName, product, zipcode, 'completed', zipCodeValidationStatus);
          }
          return serviceAreaFlag;
        }
      }),
  });

  const buttonSubmit = (e) => {

    // Validation Error.
    const zipCodevalidationError = Validation.ZipValidationCodeDataLayerValidation(zipCode, zipCodeValidation.validationPattern);

    if ((Object.keys(zipCodevalidationError).length !== 0) && (componentName !== 'ZipChecker')) {
      DataLayerService.formFailEvent(form, location, zipCodevalidationError);
    }

    if (v2) {
      scrollToTop(formRef);
    }
  }

  const handleOnBlur = (event) => {
    zipCode = event.target.value;
    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const handleTypeOfWaterClick = (value, setFieldValue) => {
    setFieldValue("typeofwater", value);
    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const handleWaterColorClick = (value, setFieldValue) => {
    setFieldValue("watercolor", value);

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const handleHouseClick = (value, setFieldValue) => {
    setFieldValue("houseType", value);

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const StyledFormContainer = styled(Paper)(() => ({
    background: "#ffffff",
    borderRadius: 0,
    maxWidth: isMobile ? "100%" : 520,
    width: "100%",
    height: isMobile ? "auto" : 720,
  }));

  return (
    <Formik
      initialValues={{
        zipCode: formData.zipCode
          ? formData.zipCode
          : localStorage.getItem("zipCode") || '',
        typeofwater: "",
        watercolor: "",
      }}
      validationSchema={ValidationSchema}
      validateOnBlur={true}
      validateOnChange={false}
      onSubmit={async (values) => {
        const cookies = await PostService.getUTMAttributes();
        const additionalInformation = {
          guid: cookies.guid,
          utm_campaign: cookies.utm_campaign ? cookies.utm_campaign.toUpperCase() : '',
        };
        const data = { ...formData, ...values, ...additionalInformation };
        setFormData(data);

        // Passing lf.form.assessment event to DataLayer.
        DataLayerService.formAssessmentEvent(data);

        setactiveLHWSStepIndex('group');

        if (userJourneyTracking) {
          let stepNumber = 1;
          if ((componentName === 'ZipChecker') || (componentName === 'ModalForm')) {
            stepNumber = 2;
          }
          // Passing lf.form.status event to DataLayer.
          DataLayerService.formStatusEvent(formName, subformName, product, stepNumber, 1, 'notcompleted');
        }

        if (v2) {
          scrollToTop(formRef);
        }

        // Passing lf.form.start event to DataLayer.
        DataLayerService.formStartEvent(form, location, data);
      }}
    >
      {({ handleSubmit, setFieldValue, values, touched, errors, isSubmitting }) => (
        <Container maxWidth={isMobile ? "xs" : "sm"} disableGutters={true}>
           <StyledFormContainer>
                <Form
                  className="flex flex-col justify-center items-start step1"
                  onSubmit={handleSubmit}
                  ref={formRef}
                  onBlur={
                    (event) => {handleOnBlur(event)}
                  }
                >
                  {formData.zipCode ? (
                    <div className="area-info px-2 pt-4 pb-1 mb-4">
                      <p className="fs-6">
                        We can’t wait for you to join over 1 million satisfied
                        customers!
                      </p>
                    </div>
                  ) : (
                    ""
                  )}

                  <h3 className={`form-step-title text-2xl fw-700 ${v2 ? 'mb-0 fw-bold' : ''}`}>What's in your water?</h3>
                  <p className="form-step-desc">Schedule your FREE water test and consultation to find out!</p>
                  <div className="label-bold">
                    <ZipCodeInput
                      fieldName={'zipCode'}
                      style={{
                        display: formData.zipCode ? "none" : "inline"
                      }}
                      label={formData.zipCode ? false : true}
                    />
                  </div>

                  <Divider className="divider" />

                  <FormControl component="fieldset">
                    <label>What type of water do you have?</label>
                    <div>
                      <Field name="product">
                        {({ field }) => (
                          <FormControl component="fieldset">
                            <div className="image-wrapper svg-icon-wrapper product-group-fieldset">
                              <FormControlLabel
                              className={`lf-icon-control-label City ${
                                values.typeofwater === 'City Water'
                                  ? "selected-border"
                                  : ""
                              }`}
                              value="City Water"
                              control={
                                <CityWaterIcon
                                    className={`custom-img ${
                                      values.typeofwater === 'City Water'
                                        ? "selected"
                                        : ""
                                    }`}
                                    alt="City Water"
                                    onClick={() =>
                                      handleTypeOfWaterClick(
                                        "City Water",
                                        setFieldValue
                                      )
                                    }
                                  />
                                }
                                label={
                                  <Typography
                                    variant="body4"
                                    className={`image-wrapper-label-text ${
                                      values.typeofwater === 'City Water'
                                        ? "selected"
                                        : ""
                                    }`}
                                  >
                                    City Water
                                  </Typography>
                                }
                              />
                              <FormControlLabel
                              className={`lf-icon-control-label Well ${
                                values.typeofwater === 'Well Water'
                                  ? "selected-border"
                                  : ""
                              }`}
                                value="Well Water"
                                control={
                                  <WellWaterIcon
                                    className={`custom-img ${
                                      values.typeofwater === 'Well Water'
                                        ? "selected"
                                        : ""
                                    }`}
                                    alt="Well Water"
                                    onClick={() =>
                                    handleTypeOfWaterClick(
                                        "Well Water",
                                        setFieldValue
                                      )
                                    }
                                  />
                                }
                                label={
                                  <Typography
                                    variant="body4"
                                    className={`image-wrapper-label-text ${
                                      values.typeofwater === 'Well Water'
                                        ? "selected"
                                        : ""
                                    }`}
                                  >
                                    Well Water
                                  </Typography>
                                }
                              />
                               <FormControlLabel
                                className={`lf-icon-control-label Unsure ${
                                  values.typeofwater === 'Unsure'
                                    ? "selected-border"
                                    : ""
                                }`}
                                value="Unsure"
                                control={
                                  <UnsureIcon
                                    className={`custom-img ${
                                      values.typeofwater === 'Unsure'
                                        ? "selected"
                                        : ""
                                    }`}
                                    alt="Unsure"
                                    onClick={() =>
                                    handleTypeOfWaterClick(
                                        "Unsure",
                                        setFieldValue
                                      )
                                    }
                                  />
                                }
                                label={
                                  <Typography
                                    variant="body4"
                                    className={`image-wrapper-label-text ${
                                      values.typeofwater === 'Unsure'
                                        ? "selected"
                                        : ""
                                    }`}
                                  >
                                    Unsure
                                  </Typography>
                                }
                              />
                            </div>
                          </FormControl>
                        )}
                      </Field>
                    </div>
                  </FormControl>
                  <Divider className="divider" />
                  {homeTypeEnable === 'enable' ?
                    <>
                      <FormControl component="fieldset">
                        <label>Do you rent or own your house?</label>
                        <div>
                          <Field name="product">
                            {({ field }) => (
                              <FormControl component="fieldset">
                                <div className="image-wrapper svg-icon-wrapper product-group-fieldset">
                                  <FormControlLabel
                                  className={`lf-icon-control-label City ${
                                    values.houseType === 'Rent'
                                      ? "selected-border"
                                      : ""
                                  }`}
                                  value="Rent"
                                  control={
                                    <RentIcon
                                        className={`custom-img ${
                                          values.houseType === 'Rent'
                                            ? "selected"
                                            : ""
                                        }`}
                                        alt="Rent"
                                        onClick={() =>
                                          handleHouseClick(
                                            "Rent",
                                            setFieldValue
                                          )
                                        }
                                      />
                                    }
                                    label={
                                      <Typography
                                        variant="body4"
                                        className={`image-wrapper-label-text ${
                                          values.houseType === 'Rent'
                                            ? "selected"
                                            : ""
                                        }`}
                                      >
                                        Rent
                                      </Typography>
                                    }
                                  />
                                  <FormControlLabel
                                  className={`lf-icon-control-label Own ${
                                    values.houseType === 'Own'
                                      ? "selected-border"
                                      : ""
                                  }`}
                                    value="Own"
                                    control={
                                      <OwnIcon
                                        className={`custom-img ${
                                          values.houseType === 'Own'
                                            ? "selected"
                                            : ""
                                        }`}
                                        alt="Own"
                                        onClick={() =>
                                          handleHouseClick(
                                            "Own",
                                            setFieldValue
                                          )
                                        }
                                      />
                                    }
                                    label={
                                      <Typography
                                        variant="body4"
                                        className={`image-wrapper-label-text ${
                                          values.houseType === 'Own'
                                            ? "selected"
                                            : ""
                                        }`}
                                      >
                                        Own
                                      </Typography>
                                    }
                                  />
                                </div>
                              </FormControl>
                            )}
                          </Field>
                        </div>
                      </FormControl>
                      <Divider className="divider" />
                    </>
                  : ''}
                  <div>
                    <FormControl component="fieldset">
                      <label>Does your water have a strong odor or taste?</label>
                      <div>
                        <Field name="projectStatus">
                          {({ field }) => (
                            <FormControl component="fieldset">
                              <div className="image-wrapper product-group-fieldset">
                                <FormControlLabel
                                 className={`lf-icon-control-label Yes ${
                                  values.watercolor === 1
                                    ? "selected-border"
                                    : ""
                                }`}

                                  value="1"
                                  control={
                                    <YesIcon
                                      className={`custom-img ${
                                        values.watercolor === 1
                                          ? "selected"
                                          : ""
                                      }`}
                                      alt="readyToHire"
                                      onClick={() =>
                                        handleWaterColorClick(
                                          1,
                                          setFieldValue
                                        )
                                      }
                                    />
                                  }
                                  label={
                                    <Typography
                                      variant="body4"
                                      className={`image-wrapper-label-text ${
                                        values.watercolor === 1
                                          ? "selected"
                                          : ""
                                      }`}
                                    >
                                     Yes
                                    </Typography>
                                  }
                                />
                                <FormControlLabel
                                 className={`lf-icon-control-label No ${
                                  values.watercolor === 2
                                    ? "selected-border"
                                    : ""
                                }`}
                                  value="2"
                                  control={
                                    <NoIcon
                                      className={`custom-img ${
                                        values.watercolor === 2
                                          ? "selected"
                                          : ""
                                      }`}
                                      alt="2000"
                                      onClick={() =>
                                        handleWaterColorClick(
                                          2,
                                          setFieldValue
                                        )
                                      }
                                    />
                                  }
                                  label={
                                    <Typography
                                      variant="body4"
                                      className={`image-wrapper-label-text ${
                                        values.watercolor === 2
                                          ? "selected"
                                          : ""
                                      }`}
                                    >
                                      No
                                    </Typography>
                                  }
                                />
                              </div>
                            </FormControl>
                          )}
                        </Field>
                      </div>
                    </FormControl>
                  </div>
                  <LeafBox style={{
                    marginTop: 2,
                    marginBottom: 3,
                  }}>
                    <PrimaryButton
                      type="submit"
                      handleClick={buttonSubmit}
                      isSubmitting={isSubmitting}
                    >
                    Next
                    </PrimaryButton>
                  </LeafBox>
                  { v2 ?
                    <ProgressBarWithPercentage />
                    :
                    <ProgressBar customClass="" />
                  }
                  <TermAndCondition />
                </Form>
            </StyledFormContainer>
        </Container>
      )}
    </Formik>
  );
}

export default InitialStep;
